<template>
  <div class="container">
    <van-nav-bar
      left-arrow
      fixed
      @click-left="onClickLeft"/>

    <div class="header">
      <img class="header-img" src="./img/beijing.png" alt="">
      <div class="discountL">
        <div class="discountL-item" v-for="x in sysCoupons" :key="x.id">
          <div class="discountL-item-container">
            <div class="discountL-item-container-type">
              <div class="discountL-item-container-type-item" v-for="(y, i) in x.tickekType" :key="y">
                <span>{{ y }}</span>
                <span
                  v-if="x.tickekType.length - 1 != i"
                  style="margin-left: 5px; transform:translateY(-1px);">|</span>
              </div>
            </div>

            <div class="discountL-item-container-bottom">
              <span class="discountL-item-container-bottom-rmb">{{ x.discountType == '0' ? x.discountRatio : '￥' }}</span>
              <span class="discountL-item-container-bottom-quantity">{{ x.discountType == '0' ? '折' : x.discountAmount }}</span>
              <div class="discountL-item-container-bottom-shuo">
                <p v-if="x.amount == 500">无门槛</p>
                <p>{{ x.name }}</p>
              </div>
            </div>
          </div>

          <div class="discountL-item-btn">
            <van-button round color="linear-gradient(#FF8D02, #FF3F00)" size="mini" @click="duihuanTicket(x)" :disabled="x.isReceive == '1'">
              <div class="discountL-item-btn-text">
                <span class="discountL-item-btn-text-tr">{{ x.needRice }}</span>
                <span class="discountL-item-btn-text-tr">驿马兑换</span>
              </div>
            </van-button>
          </div>
        </div>
      </div>
    </div>
    
    <div class="footer">
      <p class="footer-title">领券中心</p>

      <div class="footer-kong" v-if="shopCoupons.length == 0">
        <img class="footer-kong-img" src="./img/kong.png" alt="">
        <p style="color: #BD412D;">暂无优惠券</p>
      </div>

      <div class="shopList" v-for="x in shopCoupons" :key="x.shopId">
        <div class="shopList-shopDetail" @click="enterStore(x)">
          <img class="shopList-shopDetail-img" :src="x.headImgUrl" alt="">
          <span style="flex: 1;, margin-left: 7px;">{{ x.name }}</span>
          <van-icon name="arrow" />
        </div>

        <div class="shopList-discountList" v-for="y in x.coupons" :key="y.id">
          <div class="shopList-discountList-item1">
            <div>
              <span style="font-size: 15px; color: #F52555;">{{ y.discountType == '0' ? y.discountRatio : '￥' }}</span>
              <span style="font-size: 20px; color: #F52555;">{{ y.discountType == '0' ? '折' : y.discountAmount }}</span>
            </div>
            <span style="font-size: 14px; color: #000;;">店铺{{ y.discountType == '0' ? '折扣券' : '优惠券' }}</span>

            <div class="shopList-discountList-item1-top_yuan shopList-discountList-item1-yuan"></div>
            <div class="shopList-discountList-item1-bot_yuan shopList-discountList-item1-yuan"></div>
          </div>
          <div class="shopList-discountList-item2">
            <p>{{ y.name }}</p>
            <p>限该店铺{{ y.ranges == '0' ? '内' : '指定' }}商品使用</p>
          </div>
          <div></div>
          <div></div>
          <div class="shopList-discountList-item">
            <van-button round color="#F52555" size="mini" @click="receiveDiscount(y.id)" :disabled="y.isReceive == '1'">
              <span style="padding: 0 10px;">领取</span>
            </van-button>
          </div>
        </div>
      </div>
    </div>

    <vant-dialong 
      :show="show"
      :id="id"
      :needRice="needRice"
      :tickekType="tickekType"
      :discountType="discountType"
      :discountRatio="discountRatio"
      :discountAmount="discountAmount"
      :amount="amount"
      :name="name"
      @updateTicket="updateTicket"
      @useLoading="useLoading"
      @closeShow="closeShow" />

    <you-hui 
      :youhui="youhui" 
      :couponId="couponId"
      @updateTicket="updateTicket"
      @useLoading="useLoading"
      @closeYouhui="closeYouhui" />

    <loading :loading="loading" />
  </div>
</template>

<script>
import { getTicket } from '@/api/Ge/port'
import VantDialong from './VantDialong'
import YouHui from './YouHui'
import Loading from './Loading'
export default {
  components: { VantDialong, Loading, YouHui },

  data () {
    return {
      sysCoupons: [],
      shopCoupons: [],
      show: false,
      id: '',
      couponId: '',
      needRice: '',
      tickekType: [],
      discountType: '',
      discountRatio: '',
      discountAmount: '',
      amount: '',
      name: '',
      loading: false,
      youhui: false
    }
  },

  mounted() {
    this.$store.commit("setSession", this.$route.query.session)
    let screenWidth = document.documentElement.getBoundingClientRect().width
    document.documentElement.style.fontSize = screenWidth / 10 + "px"
    this.getPage()
  },

  methods: {
    getPage () {
      getTicket().then(res => {
        if (res.data.code === 0) {
          this.shopCoupons = res.data.data.shopCoupons
          res.data.data.sysCoupons.forEach(x => {
            x.discountRatio = x.discountRatio / 10
            let tickekType = []
            switch (x.type) {
              case '0': tickekType.push('红尘坊'); break
              case '1': tickekType.push('鬼市'); break
              case '2': tickekType.push('通用券'); break
              case '3': tickekType.push('侠客集市'); break
              case '4': tickekType.push('鬼市', '红尘坊'); break
              case '5': tickekType.push('鬼市', '侠客集市'); break
              case '6': tickekType.push('红尘坊', '侠客集市'); break
            }
            x.isNew == '1' ? tickekType.push('新人专享') : ''
            x.tickekType = tickekType
          })
          this.sysCoupons = res.data.data.sysCoupons
        }
      })
    },

    onClickLeft () {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up")
      }
      if (isAndroid) {
        window.android.close()
      }
    },

    duihuanTicket (x) {
      this.id = x.id
      this.needRice = String(x.needRice)
      this.tickekType = x.tickekType
      this.discountType = x.discountType
      this.discountRatio = String(x.discountRatio)
      this.discountAmount = String(x.discountAmount)
      this.amount = String(x.amount)
      this.name = x.name
      this.$nextTick(() => {
        this.show = true
      })
    },

    closeShow () {
      this.show = false
    },

    closeYouhui () {
      this.youhui = false
    },

    receiveDiscount (couponId) {
      this.youhui = true
      this.couponId = couponId
    },

    updateTicket () {
      this.getPage()
    },

    useLoading (Boolean) {
      this.loading = Boolean
    },

    enterStore (x) {
      if (x.shopType == '0') {
        this.$router.push({
          path: '/flMallStoreDetail',
          query: {
            session: this.$route.query.session,
            id: x.shopId,
            type: 'h5'
          }
        })
      } else if (x.shopType == '1') {
        this.$router.push({
          path: '/miliShop',
          query: {
            session: this.$route.query.session,
            id: x.shopId,
            type: 'h5'
          }
        })
      } else if (x.shopType == '3') {
        this.$router.push({
          path: '/ydMallStoreDetail',
          query: {
            session: this.$route.query.session,
            id: x.shopId,
            type: 'h5'
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.van-hairline--bottom::after {
  border-bottom-width: 0;
}
/deep/ .van-nav-bar {
  background: transparent;
  .van-nav-bar__left {
    padding: 0 10px;
  }
  .van-icon-arrow-left {
    color: white;
  }
}
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #fff9f0;
}
.header {
  position: relative;
  &-img {
    vertical-align: bottom;
  }
  .discountL {
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    bottom: 75px;
    padding-left: 10px;
    display: flex;
    overflow: auto;
    overflow-x: visible;
    &-item {
      width: 168px;
      height: 115px;
      flex-shrink: 0;
      margin-right: 10px;
      background-image: url('./img/beijing2.png');
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      flex-direction: column;
      &-container {
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        &-type {
          background: #f9db8a;
          border-radius: 5px 0px 7px 0px; // 不做适配
          display: flex;
          align-items: center;
          font-size: 12px;
          &-item {
            display: flex;
            align-items: center;
            transform: scale(.8);
          }
        }
        &-bottom {
          display: flex;
          align-items: center;
          &-rmb {
            color: #FF3F00;
            font-size: 15px;
            margin-left: 10px;
            transform: translateY(2px);
          }
          &-quantity {
            color: #FF3F00;
            font-size: 20px;
            margin-right: 7px;
          }
          &-shuo {
            font-size: 12px;
            color: #BD412D;
          }
        }
      }
      &-btn {
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateY(2px);
        &-text {
          display: flex;
          align-items: center;
          font-size: 12px;
          padding: 0 18px;
          &-mi {
            width: 16px;
            margin: 0 2px;
          }
          &-tr {
            transform: translateY(1px); // 是否适配
          }
        }
      }
    }
  }
  .discountL::-webkit-scrollbar {
    display: none;
  }
}
.footer {
  flex: 1;
  transform: translateY(-50px);
  background: #fff9f0;
  border-radius: 10px 10px 0 0;
  &-title {
    color: #BF1B01;
    text-align: center;
    font-size: 18px;
    padding: 10px 0;
  }
  &-kong {
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-img {
      width: 120px;
    }
  }
  .shopList {
    width: 96%;
    box-sizing: border-box;
    background: white;
    margin: auto;
    border-radius: 8px; // 不做适配
    padding: 10px;
    margin-bottom: 10px;
    &-shopDetail {
      display: flex;
      align-items: center;
      display: flex;
      margin-bottom: 10px;
      &-img {
        width: 48px;
        height: 48px;
        border-radius: 4px; // 不做适配
        margin-right: 7px;
      }
    }
    &-discountList {
      border: 1px solid #E73227;
      background: #FFF5F4;
      border-radius: 4px; // 不做适配
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 15px;
      margin-bottom: 10px;
      &-item1 {
        border-right: 1px dashed #E73227;
        padding-right: 10px;
        position: relative;
        &-yuan {
          width: 16px;
          height: 8px;
          border: 1px solid #E73227;
          position: absolute;
          background: white;
        }
        &-top_yuan {
          border-radius: 0 0 10px 10px;
          border-top: 1px solid transparent;
          position: absolute;
          right: -8px;
          top: -13px;
        }
        &-bot_yuan {
          border-radius: 10px 10px 0 0;
          border-bottom: 1px solid transparent;
          position: absolute;
          right: -8px;
          bottom: -14px;
        }
      }
      &-item2 {
        font-size: 12px;
        color: #333;
      }
    }
  }
}
</style>