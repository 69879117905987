<template>
  <transition name="ani">
    <div class="zhezhaoceng" v-if="youhui" @click.self="hideMask">
      <div class="zhezhaoceng-box">
        <van-icon name="cross" size="20" class="zhezhaoceng-box-x" @click="hideMask" />
        <p class="zhezhaoceng-box-subTitle">优惠券</p>
        <p class="zhezhaoceng-box-title">确认领取优惠券？</p>

        <div class="zhezhaoceng-box-btn">
          <van-button round color="#F52555" size="large" @click="receiveDiscount">领取</van-button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { duihuanTicket } from '@/api/Ge/port'
import { Toast } from 'vant'
export default {
  props: {
    youhui: {
      type: Boolean,
      required: true
    },
    couponId: {
      type: String,
      required: true
    }
  },

  computed: {
    _youhui: {
      get () {
        return this.youhui
      },
      set () {
        this.$emit('closeYouhui')
      }
    }
  },

  methods: {
    hideMask () {
      this._youhui = false
    },

    receiveDiscount () {
      this.$emit('useLoading', true)
      this._youhui = false
      duihuanTicket({ couponId: this.couponId }).then(res => {
        if (res.data.code === 0) {
          this.$emit('useLoading', false)
          Toast('领取成功')
          this.$emit('updateTicket')
        } else {
          this.$emit('useLoading', false)
          Toast(res.data.message)
        }
      }).catch(() => {
        this.$emit('useLoading', false)
        Toast('兑换失败')
      })
    }
  }
}
</script>

<style lang="less" scoped>
.zhezhaoceng {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  &-box {
    padding:  30px 20px;
    background: #fff;
    border-radius: 20px;
    position: relative;
    width: 300px;
    box-sizing: border-box;
    &-x {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    &-subTitle {
      text-align: center;
      font-size: 20px;
    }
    &-title {
      text-align: center;
      font-size: 14px;
      margin: 15px 0 20px;
    }
    &-btn {
      margin-top: 25px;
      .van-button--large {
        height: 44px;
      }
    }
  }
}
.ani-enter {
  opacity: 0;
}
.ani-leave {
  opacity: 1;
}
.ani-leave-to {
  opacity: 0;
}
.ani-enter-active,
.ani-leave-active {
  transition: all .5s;
}
</style>