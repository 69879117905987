<template>
  <transition name="ani">
    <div class="zhezhaoceng" v-if="_show" @click.self="hideMask">
      <div class="container">
        <van-icon name="cross" size="20" class="container-x" @click="hideMask" />
        <p class="container-subTitle">是否兑换</p>
        <p class="container-title">本次兑换将消耗<span style="color: #F52555;">{{ needRice }}驿马</span></p>
        <div class="container-shuo">
          <div class="container-shuo-item1">
            <div>
              <span>{{ needRice }}</span>
              <span style="color: #F52555;">驿马</span>
            </div>
          </div>

          <div class="container-shuo-item2">
            <img class="container-shuo-item2-img" src="./img/jian.png" alt="">
          </div>

          <div class="container-shuo-item3">
            <div class="quan">
              <div class="quan-item" v-for="(x, i) in tickekType" :key="x">
                <span>{{ x }}</span>
                <span style="margin: 0 5px; transform:" v-if="tickekType.length - 1 != i">|</span>
              </div>
            </div>

            <span style="font-size: 15px; color: #FF3F00; transform: translateY(1px);">{{ discountType == '0' ? discountRatio : '￥' }}</span>
            <span style="font-size: 20px; color: #F52555; margin-right: 7px;">{{ discountType == '0' ? '折' : discountAmount }}</span>
            <div style="font-size: 12px; color: #BD412D;">
              <p v-if="amount == '500'">无门槛</p>
              <p>{{ name }}</p>
            </div>
          </div>
        </div>

        <div class="container-btn">
          <van-button round color="#F52555" size="large" @click="confirm">确认兑换</van-button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { duihuanTicket } from '@/api/Ge/port'
import { Toast } from 'vant'
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    id: {
      type: String,
    },
    needRice: {
      type: String,
    },
    tickekType: {
      type: Array,
      default: () => []
    },
    discountType: {
      type: String,
    },
    discountRatio: {
      type: String,
    },
    discountAmount: {
      type: String,
    },
    amount: {
      type: String,
    },
    name: {
      type: String,
    },
  },

  computed: {
    _show: {
      get () {
        return this.show
      },
      set () {
        this.$emit('closeShow')
      }
    }
  },

  data () {
    return {
      loading: false
    }
  },

  methods: {
    confirm () {
      this.$emit('useLoading', true)
      this._show = false
      duihuanTicket({ couponId: this.id }).then(res => {
        if (res.data.code === 0) {
          this.$emit('useLoading', false)
          Toast('兑换成功')
          this.$emit('updateTicket')
        } else {
          this.$emit('useLoading', false)
          Toast(res.data.message)
        }
      }).catch(() => {
        this.$emit('useLoading', false)
        Toast('兑换失败')
      })
    },

    hideMask () {
      this._show = false
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  padding:  30px 20px;
  background: #fff;
  border-radius: 20px;
  position: relative;
  width: 300px;
  box-sizing: border-box;
  &-x {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  &-subTitle {
    text-align: center;
    font-size: 20px;
  }
  &-title {
    text-align: center;
    font-size: 14px;
    margin: 15px 0 20px;
  }
  &-shuo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-item2 {
      &-img {
        width: 28px;
      }
    }
    &-item3 {
      width: 168px;
      height: 70px;
      padding-left: 10px;
      box-sizing: border-box;
      background-image: url('./img/beijing3.png');
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      align-items: center;
      position: relative;
      .quan {
        background: #f9db8a;
        color: #906D08;
        position: absolute;
        left: 0;
        top: 0;
        font-size: 12px;
        display: flex;
        align-items: center;
        border-radius: 5px 0px 7px 0px;
        &-item {
          transform: scale(.8);
        }
      }
    }
  }
  &-btn {
    margin-top: 25px;
    .van-button--large {
      height: 44px;
    }
  }
}
.zhezhaoceng {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.ani-enter {
  opacity: 0;
}
.ani-leave {
  opacity: 1;
}
.ani-leave-to {
  opacity: 0;
}
.ani-enter-active,
.ani-leave-active {
  transition: all .5s;
}
</style>